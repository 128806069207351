import { AbstractControl, FormControl, ValidationErrors } from "@angular/forms";
import { StaticMessages } from "../static-messages";
import * as moment from 'moment';
export class CustomValidators {
  static birthDateExpression: RegExp = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/;
  static vaildEmail(c: FormControl): ValidationErrors {
    const email = c.value;
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = true;
    const message = {
      vaildEmail: {
        message: StaticMessages.invalidEmailOrPassword
      }
    };
    if (reg.test(email)) {
      isValid = true;
    } else {
      isValid = false;
    }
    return isValid ? null : message;
  }
  static passwordAdminValidate(c: FormControl): ValidationErrors {
    const password = c.value;

    //const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\[\]"\';:_\-<>\., =\+\/\\]).{8,}$/;
    const reg = /^([a-zA-Z0-9@*#$%^_]{6,15})$/;
    let isValid = true;
    const message = {
      validPassword: {
        message: StaticMessages.adminPasswordFomrmatMsg
      }
    };
    if (reg.test(password)) {
      isValid = true;
    } else {
      isValid = false;
    }
    return isValid ? null : message;
  }
  static vacancyDate(control: FormControl): ValidationErrors {
    const isValid = CustomValidators.birthDateExpression.test(control.value);
    if (isValid) {
      const uintputDate = new Date(control.value.split('/')[1] + '/' + control.value.split('/')[0] + '/' + control.value.split('/')[2]);
      // const age = moment().diff(uintputDate, 'years', true);
      if (moment(moment(new Date()).format('MM/DD/YYYY')).isSameOrBefore(uintputDate)) {
        // const date = moment().diff(uintputDate, 'years', true);
        // if (date < 0) {
        //   return null;
        // } else {
        //   return { 'ptValidAge': true };
        // }
        return null;
      } else {
        return { 'ptValidAge': true };
      }
    } else {
      return { ptDate: true };
    }
  }
  static shouldNotBeGreaterThanToday(control: FormControl): ValidationErrors {
    const isValid = CustomValidators.birthDateExpression.test(control.value);
    if (isValid) {
      return CustomValidators.ageBetweenTwoDateRange(control, 0, 120);
    } else {
      return { ptDate: true };
    }
  }
  static ageBetweenTwoDateRange(control: FormControl, minAge: number, maxAge: number) {
    const uintputDate = new Date(control.value.split('/')[1] + '/' + control.value.split('/')[0] + '/' + control.value.split('/')[2]);
    if (moment(new Date()).isBefore(uintputDate)) {
      return { ptDateIsGreater: true };
    } else {
      const age = moment().diff(uintputDate, 'years', true);
      if (age >= minAge && age < maxAge) {
        return null;
      } else {
        return { 'ptValidAge': true };
      }
    }
  }
  static CNPJ() {
    return (control: AbstractControl): ValidationErrors | null => {
      let value: string = control.value;
      if (!value) return null;

      let cnpj = (value + "").replace(/[^\d]+/g, "");
      if (cnpj === "") return { cnpjInvalid: true };
      if (cnpj.length !== 14) return { cnpjInvalid: true };

      // Elimina CNPJs invalidos conhecidos
      if (
        cnpj === "00000000000000" ||
        cnpj === "11111111111111" ||
        cnpj === "22222222222222" ||
        cnpj === "33333333333333" ||
        cnpj === "44444444444444" ||
        cnpj === "55555555555555" ||
        cnpj === "66666666666666" ||
        cnpj === "77777777777777" ||
        cnpj === "88888888888888" ||
        cnpj === "99999999999999"
      ) {
        return { cnpjInvalid: true };
      }

      let tamanho: number = cnpj.length - 2;
      let numeros: string = cnpj.substring(0, tamanho);
      let digitos: string = cnpj.substring(tamanho);
      let soma: number = 0;
      let pos: number = tamanho - 7;

      for (var i = tamanho; i >= 1; i--) {
        soma += Number(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) pos = 9;
      }

      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado + "" !== digitos.charAt(0)) {
        return { cnpjInvalid: true };
      }

      tamanho = tamanho + 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;

      for (var j = tamanho; j >= 1; j--) {
        soma += Number(numeros.charAt(tamanho - j)) * pos--;
        if (pos < 2) pos = 9;
      }

      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado + "" !== digitos.charAt(1)) {
        return { cnpjInvalid: true };
      }

      return null;

    }
  }
}
