import { DOCUMENT } from '@angular/common';
import { Directive, HostBinding, HostListener, Inject } from '@angular/core'

@Directive({
  selector: '[pathFilter]',
})
export class PathFilterDirective {
  @HostBinding('autocomplete') public autocomplete
  constructor(@Inject(DOCUMENT) private document: Document) {
    this.autocomplete = 'off'
  }
  @HostListener('keypress', ['$event']) public disableKeys(e: any) {
    this.document.all ? e.keyCode : e.keyCode    
    return e.keyCode == 45 || (e.keyCode >= 97 && e.keyCode <= 122)
  }
}
