export const StaticMessages = {
    ok: "Ok.",
    invalidCnpj: "Digite o CNPJ da empresa",
    invalidCompanyName: "Digite o nome fantasia da empresa.",
    invalidSocialContact: "Digite a razão social da empresa.",
    invalidCEP: "Digite o CEP da empresa.",
    invalidCargo: "Escolha o cargo oferecido para a vaga.",
    invalidTelephone: "Digite o telefone do solicitante.",
    requiredEmail: "Digite o email do solicitante.",
    invalidEmail: "Por favor insira um email válido.",
    invalidTitle: "adicione a posição oferecida para a vaga.",
    invalidVacancyDescription: "Digite a descrição necessária para a vaga",
    invalidScholarshipAmount: "Digite o valor da bolsa",
    invalidNumero: "Digite o número de endereço da empresa.",
    invalidEmailOrPassword: "Invalid Email or Password",
    somethingWentWrong: "Something went wrong",
    noRecordSelected: 'Nenhum registro selecionado.',
    adminPasswordFomrmatMsg: "Mínimo de 6 caracteres",
    thisFieldIsRequired: "Campo obrigatório.",
    recordUpdatedSuccessfully: 'Registro atualizado com sucesso.',
    youngsterRemovedFromList: 'Jovem removido da lista.',
    emailAlreadyUsed: "Email já utilizado.",
    yourPasswordAndConfirmPasswordDoesnotMatch: 'Sua senha e confirme a senha não corresponde.',
    pleaseEnterValidEmail: 'Por favor insira um email válido.',
    twoCharactersRequired: 'São necessários 2 caracteres.',
    allYoungstersAlreadySelected: 'Todos os jovens já estão selecionados.',
    uploadImage: 'Atualizar foto', // for button.
    resizeImage: 'Redimensionar foto', // for button.
    hiredYoungstersEqualVacancy: 'Você já preencheu todas as _vacancycount_ vagas com jovens.',
    hired: 'Contratado',
    rejected: 'Reprovado',
    didNotAvailabeForInterview: 'Faltou',
    thereIsSomeError: 'Ocorreu algum problema, por favor tente novamente.',
    pleaseEnterValidTelephone: 'Por favor insira um telefone válido.',
  };
  