import { NgModule } from '@angular/core';
import { MaskCnpjDirective } from './mask-cnpj/mask-cnpj.directive';
import { PathFilterDirective } from './mask-path/mask-path.directive';

@NgModule({
  declarations: [MaskCnpjDirective, PathFilterDirective],
  providers: [],
  imports: [],
  exports: [MaskCnpjDirective, PathFilterDirective]
})
export class DirectivesModule { }
